import { defineStore } from 'pinia'
import { ref, onMounted } from 'vue'
import axios from '@/axios'

export const useLanguageStore = defineStore('language', () => {
  const documentLanguageCode = document.documentElement.lang
  const language = ref({
    code: documentLanguageCode,
  })
  const languages = ref([])

  onMounted(async () => {
    await fetchLanguages()

    language.value = languages.value.filter(
      (language) => language.code === documentLanguageCode
    )[0]
  })

  async function fetchLanguages() {
    if (languages.value.length > 0) {
      return
    }

    const response = await axios.get('/api/languages')

    languages.value = response.data.data
    return languages.value
  }

  return { language, languages }
})
