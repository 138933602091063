import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import axios from '@/axios'
import { useDataAgeStore } from '@/stores/dataAge'

export const useLatestPriceStore = defineStore('latest-price', () => {
  const prices = ref([])
  const isLoading = ref(true)
  const pricesParameters = ref(null)

  const dataAgeStore = useDataAgeStore()

  async function fetchPrices(parameters) {
    if (typeof parameters !== 'undefined') {
      pricesParameters.value = parameters
    }

    if (pricesParameters.value === null) {
      return
    }

    isLoading.value = true

    const response = await axios.get('/api/pricematrix', {
      params: {
        ...pricesParameters.value,
        cacheKey: dataAgeStore.age,
      },
    })

    prices.value = response.data.data
    isLoading.value = false

    return prices.value
  }

  function refetch() {
    fetchPrices()
  }

  const futureElectricityPrices = computed(() => {
    return prices.value.filter((price) => {
      return (
        price.product === 'Future' &&
        price.utilitytype === 'Electricity' &&
        price.deliveryperiod === 'Y'
      )
    })
  })

  const futureGasPrices = computed(() => {
    return prices.value.filter((price) => {
      return (
        price.product === 'Future' &&
        price.utilitytype === 'Natural Gas' &&
        price.deliveryperiod === 'Y'
      )
    })
  })

  const spotElectricityYearPrices = computed(() => {
    return prices.value.filter((price) => {
      return (
        price.product === 'SpotYear' &&
        price.utilitytype === 'Electricity' &&
        price.deliveryperiod === 'D'
      )
    })
  })

  const spotGasYearPrices = computed(() => {
    return prices.value.filter((price) => {
      return (
        price.product === 'SpotYear' &&
        price.utilitytype === 'Natural Gas' &&
        price.deliveryperiod === 'D'
      )
    })
  })

  const spotElectricityMonthPrices = computed(() => {
    return prices.value.filter((price) => {
      return (
        price.product === 'SpotMonth' &&
        price.utilitytype === 'Electricity' &&
        price.deliveryperiod === 'D'
      )
    })
  })

  const spotGasMonthPrices = computed(() => {
    return prices.value.filter((price) => {
      return (
        price.product === 'SpotMonth' &&
        price.utilitytype === 'Natural Gas' &&
        price.deliveryperiod === 'D'
      )
    })
  })

  const fuelPrices = computed(() => {
    return prices.value.filter((price) => {
      return price.product === 'Future' && price.countrycode === 'EU'
    })
  })

  return {
    prices,
    isLoading,
    fetchPrices,
    refetch,
    futureElectricityPrices,
    futureGasPrices,
    spotElectricityYearPrices,
    spotGasYearPrices,
    spotElectricityMonthPrices,
    spotGasMonthPrices,
    fuelPrices,
  }
})
