import { defineStore } from 'pinia'
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useCountryChangeEvent } from '@/composables/events/countryChangeEvent'
import axios from '@/axios'

function browserCountryCode() {
  switch (navigator.language.toLowerCase()) {
    case 'fr':
    case 'fr-fr':
    case 'fr-be':
    case 'nl-be':
      return 'be'
    case 'de':
    case 'de-de':
    case 'de-at':
    case 'de-ch':
    case 'de-li':
    case 'de-lu':
      return 'de'
    default:
      return 'nl'
  }
}

export const useCountryStore = defineStore('country', () => {
  const defaultCountry = ref({ code: 'nl' })
  const selectedCountry = ref(null)
  const country = computed(() => selectedCountry.value ?? defaultCountry.value)

  const countries = ref([])

  const initialized = ref(false)

  onMounted(async () => {
    await fetchCountries()
    setDefaultCountry()
    initSelectedCountry()

    nextTick(() => {
      initialized.value = true
    })
  })

  const countryChangeEvent = useCountryChangeEvent()

  watch(country, (newCountry) => {
    if (initialized.value) {
      countryChangeEvent.emit(newCountry)
    }
  })

  function setDefaultCountry(code = null) {
    code = code ?? browserCountryCode()

    const country = mapCountryCode(code)
    if (!country) {
      defaultCountry.value = { code: code }
      return
    }
    defaultCountry.value = country
  }

  function initSelectedCountry() {
    const route = useRoute()
    const queryParamCountryCode = route?.query?.country
    if (queryParamCountryCode && languageCodeExists(queryParamCountryCode)) {
      setCountryByCode(queryParamCountryCode)
      return
    }

    const localStorageCountryCode = localStorage.getItem('lastCountry')
    if (
      localStorageCountryCode &&
      languageCodeExists(localStorageCountryCode)
    ) {
      setCountryByCode(localStorageCountryCode)
    }
  }

  async function fetchCountries() {
    if (countries.value.length > 0) {
      return
    }

    const response = await axios.get('/api/country')

    countries.value = response.data.data

    return countries.value
  }

  function mapCountryCode(code) {
    return countries.value.find((country) => country.code === code)
  }

  function setCountryByCode(code) {
    const newCountry = mapCountryCode(code)
    if (typeof newCountry === 'undefined') {
      return
    }

    selectedCountry.value = newCountry
    localStorage.setItem('lastCountry', code)
  }

  function languageCodeExists(code) {
    if (typeof code !== 'string') {
      return false
    }

    const country = countries.value.find((country) => country.code === code)

    return typeof country !== 'undefined'
  }

  return {
    country,
    countries,
    setCountryByCode,
    setDefaultCountry,
    initialized,
  }
})
