import { ref } from 'vue'

const deviceType = ref(null)
const deviceOrientation = ref(null)
const deviceOnline = ref(true)
const devicePWA = ref(false)
const deviceIOS = ref(false)

updateDeviceType()
updateDeviceOrientation()
updateDeviceOnline()
updateDevicePWA()
updateDeviceIOS()

window.addEventListener('resize', updateDeviceType)
window.addEventListener('orientationchange', updateDeviceOrientation)
window.addEventListener('online', updateDeviceOnline)
window.addEventListener('offline', updateDeviceOnline)

function updateDeviceType() {
  if (window.matchMedia('(min-width: 768px)').matches) {
    deviceType.value = 'desktop'

    return
  }

  deviceType.value = 'mobile'
}

function updateDeviceOrientation() {
  if (screen.orientation.angle === 90 || screen.orientation.angle === 180) {
    deviceOrientation.value = 'landscape'

    return
  }

  deviceOrientation.value = 'portrait'
}

function updateDeviceOnline() {
  deviceOnline.value = navigator.onLine
}

function updateDevicePWA() {
  if (window.matchMedia('(display-mode: standalone)').matches) {
    devicePWA.value = true

    return
  }

  if (window.navigator.standalone) {
    devicePWA.value = true

    return
  }

  devicePWA.value = false
}

function updateDeviceIOS() {
  const platforms = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ]

  if (platforms.includes(navigator.platform)) {
    deviceIOS.value = true

    return
  }

  if (navigator.userAgent.includes('Mac') && 'ontouchend' in document) {
    deviceIOS.value = true

    return
  }

  deviceIOS.value = false
}

export function useDevice() {
  function isMobile() {
    return deviceType.value === 'mobile'
  }

  function isMobilePortrait() {
    return (
      deviceType.value === 'mobile' && deviceOrientation.value === 'portrait'
    )
  }

  function isDesktop() {
    return deviceType.value === 'desktop'
  }

  function isOnline() {
    return deviceOnline.value
  }

  function isOffline() {
    return !deviceOnline.value
  }

  function isPWA() {
    return devicePWA.value
  }

  function isIOS() {
    return devicePWA.value
  }

  return {
    isMobile,
    isMobilePortrait,
    isDesktop,
    isOnline,
    isOffline,
    isPWA,
    isIOS,
  }
}
