import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from '@/axios'
import { useDataAgeStore } from './dataAge'

export const useFuturePriceStore = defineStore('future-price', () => {
  const graphPrices = ref([])
  const matrixPrices = ref([])

  const graphPricesParameters = ref(null)
  const matrixPricesParameters = ref(null)

  const dataAgeStore = useDataAgeStore()

  const hasError = ref({
    graphPrices: false,
    matrixPrices: false,
  })

  const isLoading = ref({
    graphPrices: false,
    matrixPrices: false,
  })

  async function fetchGraphPrices(parameters) {
    if (typeof parameters !== 'undefined') {
      graphPricesParameters.value = parameters
    }

    if (graphPricesParameters.value === null) {
      return
    }

    hasError.value.graphPrices = false
    isLoading.value.graphPrices = true

    axios
      .get('/api/future', {
        params: {
          ...withCalendarParameter(graphPricesParameters.value),
          cacheKey: dataAgeStore.age,
        },
      })
      .then((response) => {
        graphPrices.value = response.data.data
      })
      .catch(() => {
        hasError.value.graphPrices = true
      })
      .finally(() => {
        isLoading.value.graphPrices = false
      })
  }

  async function fetchMatrixPrices(parameters) {
    if (typeof parameters !== 'undefined') {
      matrixPricesParameters.value = parameters
    }

    if (matrixPricesParameters.value === null) {
      return
    }

    hasError.value.matrixPrices = false
    isLoading.value.matrixPrices = true

    axios
      .get('/api/future/latest', {
        params: {
          ...withCalendarParameter(matrixPricesParameters.value),
          cacheKey: dataAgeStore.age,
        },
      })
      .then((response) => {
        matrixPrices.value = response.data.data
      })
      .catch(() => {
        hasError.value.matrixPrices = true
      })
      .finally(() => {
        isLoading.value.matrixPrices = false
      })
  }

  function refetch() {
    fetchGraphPrices()
    fetchMatrixPrices()
  }

  function withCalendarParameter(parameters) {
    if (parameters.calendar) {
      return parameters
    }

    if (parameters.utilitytype === 'Electricity') {
      return {
        ...parameters,
        calendar: 12,
      }
    }

    return {
      ...parameters,
      calendar: -1,
    }
  }

  return {
    graphPrices,
    matrixPrices,
    fetchGraphPrices,
    fetchMatrixPrices,
    refetch,
    hasError,
    isLoading,
  }
})
