import { useBlogStore } from '@/stores/blog'
import { useLatestPriceStore } from '@/stores/latestPrice'
import { useFuturePriceStore } from '@/stores/futurePrice'
import { useSpotPriceStore } from '@/stores/spotPrice'
import { useFuelPriceStore } from '@/stores/fuelPrice'

export function useRefreshEvent() {
  const blogStore = useBlogStore()
  const latestPriceStore = useLatestPriceStore()
  const spotPriceStore = useSpotPriceStore()
  const fuelPriceStore = useFuelPriceStore()
  const futurePriceStore = useFuturePriceStore()

  function emit(age, previousDataAge) {

    /**
     * age: comes from /api/checkAge and is the latest entry of the DataUpdates table.
     * previousDataAge: the age value from the dataAge store which will be used as cacheKey in the fetching requests.
     * Conclusion if new entry comes in DataUpdates table, only then we want to refresh the data.
     */
    if(age && previousDataAge) {
      /**
       * Check only if an age and previousDataAge are given in the refreshEvent. Why? This argument is given only in the refresh emit event on every check interval.
       * In this case I want to maybe skip a refresh if it's unnecessary. In the case we want to force a refresh (refresh button top left selection) then the stores
       * will re-fetch all data.
       * So here we check if date od latest record in DataUpdates table is smaller or equal with the cacheKey date (previousDataAge).
       * If data is updated a new record will be put in the table and thus skip the following condition --> re-fetch of stores.
       */
      if(age <= previousDataAge) return
    }

    blogStore.refetch()
    latestPriceStore.refetch()
    spotPriceStore.refetch()
    futurePriceStore.refetch()
    fuelPriceStore.refetch()
  }

  return { emit }
}
