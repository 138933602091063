import { useBlogStore } from '@/stores/blog'
import { usePriceTypeStore } from '@/stores/priceType'

export function useCountryChangeEvent() {
  const blogStore = useBlogStore()
  const priceTypeStore = usePriceTypeStore()

  function emit(country) {
    blogStore.refetch()
    priceTypeStore.reset()
  }

  return { emit }
}
