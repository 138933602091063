import { defineStore } from 'pinia'
import { useSharedBlogStore } from '@/composables/useSharedBlogStore'

export const useBlogStore = defineStore('blog', () => {
  const sharedBlogStore = useSharedBlogStore(true)

  function find(id) {
    return sharedBlogStore.posts.value.find((post) => post.blogkey === parseInt(id))
  }

  async function refetch() {
    const promises = []
    if (sharedBlogStore.posts.length > 0) {
      promises.push(sharedBlogStore.fetchPosts())
    }

    if (sharedBlogStore.post.value !== null) {
      promises.push(sharedBlogStore.fetchPost())
    }

    return Promise.all(promises)
  }

  async function preloadPost(id) {
    const preloadedPost = find(id)

    if (typeof preloadedPost !== 'undefined') {
      sharedBlogStore.post.value = preloadedPost
    }
  }

  return {
    ...sharedBlogStore,
    refetch,
    preloadPost,
  }
})
