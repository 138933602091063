import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from '@/axios'
import { useDataAgeStore } from '@/stores/dataAge'

export const useFuelPriceStore = defineStore('fuel-price', () => {
  const graphPrices = ref([])
  const graphPricesParameters = ref(null)
  const hasError = ref({
    graphPrices: false,
  })
  const isLoading = ref({
    graphPrices: false,
  })

  const dataAgeStore = useDataAgeStore()

  async function fetchGraphPrices(parameters) {
    if (typeof parameters !== 'undefined') {
      graphPricesParameters.value = parameters
    }

    if (graphPricesParameters.value === null) {
      return
    }

    hasError.value.graphPrices = false
    isLoading.value.graphPrices = true

    axios
      .get('/api/fuel', {
        params: {
          ...graphPricesParameters.value,
          cacheKey: dataAgeStore.age,
        },
      })
      .then((response) => {
        graphPrices.value = response.data.data
      })
      .catch(() => {
        hasError.value.graphPrices = true
      })
      .finally(() => {
        isLoading.value.graphPrices = false
      })
  }

  function refetch() {
    fetchGraphPrices()
  }

  return {
    graphPrices,
    fetchGraphPrices,
    refetch,
    hasError,
    isLoading,
  }
})
