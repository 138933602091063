import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from '@/axios'
import { useDataAgeStore } from '@/stores/dataAge'

export const useSpotPriceStore = defineStore('spot-price', () => {
  const dayPrices = ref([])
  const hourPrices = ref([])
  const monthPrices = ref([])
  const yearPrices = ref([])
  const averages = ref({
    Electricity: [],
    'Natural Gas': [],
  })

  const averagesParameters = ref(null)
  const yearPricesParameters = ref(null)
  const monthPricesParameters = ref(null)
  const dayPricesParameters = ref(null)
  const hourPricesParameters = ref(null)

  const hasError = ref({
    averages: false,
    monthPrices: false,
    dayPrices: false,
    hourPrices: false,
  })

  const isLoading = ref({
    averages: false,
    monthPrices: false,
    dayPrices: false,
    hourPrices: false,
  })

  const dataAgeStore = useDataAgeStore()

  function downloadPdf(parameters) {
    parameters._token = document.head.querySelector(
      'meta[name="csrf-token"]'
    ).content

    window.open(
      '/api/print/pricetable?' +
        new URLSearchParams(withCalendarParameter(parameters)).toString()
    )
  }

  async function fetchAverages(parameters) {
    if (typeof parameters !== 'undefined') {
      averagesParameters.value = parameters
    }

    if (averagesParameters.value === null) {
      return
    }

    hasError.value.averages = false
    isLoading.value.averages = true

    axios
      .get('/api/spot/averages', {
        params: {
          ...withCalendarParameter(averagesParameters.value),
          cacheKey: dataAgeStore.age,
        },
      })
      .then((response) => {
        averages.value[parameters.utilityType] = reduceAverages(
          response.data.data
        )
      })
      .catch(() => {
        hasError.value.averages = true
      })
      .finally(() => {
        isLoading.value.averages = false
      })
  }

  function reduceAverages(responseData) {
    return responseData.reduce(
      (groups, item) => {
        if (item.type === 'year') {
          groups.year = item
        } else {
          groups.months.push(item)
        }
        return groups
      },
      { year: null, months: [] }
    )
  }

  async function fetchYearPrices(parameters) {
    if (typeof parameters !== 'undefined') {
      yearPricesParameters.value = parameters
    }

    if (yearPricesParameters.value === null) {
      return
    }

    hasError.value.yearPrices = false
    isLoading.value.yearPrices = true

    axios
      .get('/api/spot/averages', {
        params: {
          ...withCalendarParameter(yearPricesParameters.value),
          cacheKey: dataAgeStore.age,
        },
      })
      .then((response) => {
        yearPrices.value = response.data.data.filter(
          (item) => item.type === 'year'
        )
      })
      .catch(() => {
        hasError.value.yearPrices = true
      })
      .finally(() => {
        isLoading.value.yearPrices = false
      })
  }

  async function fetchMonthPrices(parameters) {
    if (typeof parameters !== 'undefined') {
      monthPricesParameters.value = parameters
    }

    if (monthPricesParameters.value === null) {
      return
    }

    hasError.value.monthPrices = false
    isLoading.value.monthPrices = true

    axios
      .get('/api/spot/averages', {
        params: {
          ...withCalendarParameter(monthPricesParameters.value),
          cacheKey: dataAgeStore.age,
        },
      })
      .then((response) => {
        monthPrices.value = response.data.data.filter(
          (item) => item.type === 'month'
        )
      })
      .catch(() => {
        hasError.value.monthPrices = true
      })
      .finally(() => {
        isLoading.value.monthPrices = false
      })
  }

  async function fetchDayPrices(parameters) {
    if (typeof parameters !== 'undefined') {
      dayPricesParameters.value = parameters
    }

    if (dayPricesParameters.value === null) {
      return
    }

    hasError.value.dayPrices = false
    isLoading.value.dayPrices = true

    axios
      .get('/api/spot', {
        params: {
          ...withCalendarParameter(dayPricesParameters.value),
          cacheKey: dataAgeStore.age,
        },
      })
      .then((response) => {
        dayPrices.value = response.data.data
      })
      .catch(() => {
        hasError.value.dayPrices = true
      })
      .finally(() => {
        isLoading.value.dayPrices = false
      })
  }

  async function fetchHourPrices(parameters) {
    if (typeof parameters !== 'undefined') {
      hourPricesParameters.value = parameters
    }

    if (hourPricesParameters.value === null) {
      return
    }

    if (parameters.utilitytype !== 'Electricity') {
      return
    }

    hasError.value.hourPrices = false
    isLoading.value.hourPrices = true

    axios
      .get('/api/spothourly', {
        params: {
          ...withCalendarParameter(hourPricesParameters.value),
          cacheKey: dataAgeStore.age,
        },
      })
      .then((response) => {
        hourPrices.value = response.data.data
      })
      .catch(() => {
        hasError.value.hourPrices = true
      })
      .finally(() => {
        isLoading.value.hourPrices = false
      })
  }

  function refetch() {
    fetchAverages()
    fetchYearPrices()
    fetchMonthPrices()
    fetchDayPrices()
    fetchHourPrices()
  }

  function withCalendarParameter(parameters) {
    if (parameters.calendar) {
      return parameters
    }

    if (
      parameters.country === 'nl' &&
      parameters.utilitytype === 'Electricity'
    ) {
      return {
        ...parameters,
        calendar: 16,
      }
    }

    if (
      parameters.country === 'be' &&
      parameters.utilitytype === 'Electricity'
    ) {
      return {
        ...parameters,
        calendar: 12,
      }
    }

    if (
      parameters.country === 'de' &&
      parameters.utilitytype === 'Electricity'
    ) {
      return {
        ...parameters,
        calendar: 12,
      }
    }

    if (
      parameters.country === 'at' &&
      parameters.utilitytype === 'Electricity'
    ) {
      return {
        ...parameters,
        calendar: 12,
      }
    }

    return {
      ...parameters,
      calendar: -1,
    }
  }

  return {
    dayPrices,
    hourPrices,
    monthPrices,
    yearPrices,
    averages,
    fetchDayPrices,
    fetchHourPrices,
    fetchMonthPrices,
    fetchYearPrices,
    fetchAverages,
    refetch,
    downloadPdf,
    hasError,
    isLoading,
  }
})
